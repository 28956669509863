.contact-page-container{
    background-color: #f7cd62;
    /* height: 100vh; */
}
.contact-us-section-outer-con{
    /* width: 100%; */
    width: 100%;
    /* height: 100vh; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 20px; */
    /* background-color: aqua; */
    /* margin-bottom: 50px; */
    margin-top: 100px;
}


.contact-us-section-con{
    /* background-color: yellowgreen; */
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 75%;
    height: 100%;
    font-family: 'Poppins', sans-serif;
    /* margin-top: 50px; */
    /* height: 50vh; */
    /* font-family: roboto; */
}  
.contact-us-section-left-con{
    /* background-color: #e4dab7; */
    width: 30%;
    display: flex;
    /* height: 40vh; */
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}  
.contact-section-icon-con{
    /* background-color: #ffc508; */
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    
}
.each-icon-con{
    border: 1px black solid;
    width: 14%;
    height: 40px;
    border-radius: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #f7cd62; */
    margin-right: 10px;
    cursor: pointer;

}


@media (max-width: 575.98px) {

    .contact-icons-container{
        width: 40vw !important;
        /* background-color: aqua; */

    }

    .each-icon-con{
        width: 600px !important;

    }

}

.icon-color{
    color: #FFB600;
    width: 20px;
    height: 20px;
}



.contact-head-con{    
    width: 22.5vw;
    background-color: orange;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 30px;
    padding-bottom: 0px;
    
}
.contact-section-header{
    background-color: #FFB600;
    /* background-image: linear-gradient(to bottom right,#ffb600, yellow, #ffb600, yellow); */
    width: 22vw;
    font-size: 14px;
    padding: 6px;
    height: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0px;
}

.contact-text-con{
    /* background-color: aqua; */
    height: 38px;
    width: 80%;
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.contact-text-style{
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
}
.contact-us-section-right-con{
    /* background-color: #47867d; */
    width: 60%;
    /* height: 80vh; */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;

}  

.input-form-con{
    /* background-color: #e4dab7; */
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.text-area-style{
    width: 100%;
    outline: none;
}

.contact-input-styles{
    width: 48%;
    height: 40px;
    border-radius: 5px;
    outline: none;
    border: 1px solid black;
    padding-left: 10px;
    font-family: 'Poppins', sans-serif;

}

.contact-section-title{
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 1.8rem;
    /* background-color: #fa4318; */
 }

 .contact-section-text{
    font-family: 'Poppins', sans-serif;
    /* background-color: #fa4318; */
 }

 .contact-us-btn-styles{
    background-color: #FFDA7F;
   padding: 6px;
   height: 40px;
   display: flex;
   justify-content: center;
   align-items: center;
   border: 0px;
   font-size: 14px;
   cursor: pointer; 
   border-radius: 5px;
}

.contact-us-btn-styles:hover{
    background-color: #f7cd62 !important;
  
  }
@media (max-width : 600px) {
    .contact-us-section-outer-con{
        width: 100vw;
        height: 100%;
        /* padding-top: 40vh; */
        /* background-color: aqua; */
        /* margin-bottom: 60px; */

        
    }
     .contact-us-section-con{
        /* background-color: #FFB600; */
        display: flex;
        flex-direction: column;
        width: 90% !important;
     }

     .contact-section-title{
        width: 90vw;
        /* background-color: #fa4318; */
     }
     .contact-section-text{
        width: 90vw;
        /* background-color: #fa4318; */
     }

     .contact-section-icon-con{
        /* background-color: #FFB600; */
        width: 90vw;
     }
     .contact-us-section-right-con{
        /* background-color: #FFB600; */
        width: 90vw;
        /* padding-left: 10px; */
        margin-top: 10px;

     }
     .input-form-con{
        width: 85vw;
        /* background-color: #FFB600; */
     }
     .contact-input-styles{
        width: 40vw;
        border-radius: 5px;
     }
     .contact-btn-styles{
        width: 65vw;
        
        /* background-color: red; */
     }

}



@media (min-width : 1400px) {
    .each-icon-con{
        border: 1px black solid;
        width: 13%;
        height: 40px;
        border-radius: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
    }
}

@media (min-width: 2000px) {
    .contact-us-section-outer-con{
        width: 100%;
        /* height: 100%; */
        display: flex;
        justify-content: center;
        align-items: center;
        /* background-color: aqua; */
        /* margin-bottom: 100px; */
        /* margin-top: 50px; */
    }
    .contact-us-section-con{
        /* background-color: yellowgreen; */
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 65%;
        font-family: 'Poppins', sans-serif;
        /* margin-top: 50px; */
        /* height: 50vh; */
        /* font-family: roboto; */
    } 
}


.contact-icons-container{
    /* background-color: #f7cd62; */
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    margin-top: 10px;
}


